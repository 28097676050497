import { request } from "@/request/http";

export default {
  getReportInfo: (data) => {
    return request("POST", `/index.php/project/getReportInfo`, data);
  },

  getExamPaperNumber: (data) => {
    return request("POST", `/index.php/project/getExamPaperNumber`, data);
  },

  updateSortNo: (data) => {
    return request("GET", `/index.php/project/updateSortNo`, data);
  },

  previewExam: (data) => {
    return request("GET", `/index.php/project/previewExam`, data);
  },

  getEndExamSetting: (data) => {
    return request("GET", `/index.php/project/getEndExamSetting`, data);
  },

  updateVirtualLevel: (data) => {
    return request("POST", `/index.php/project/updateVirtualLevel`, data);
  },

  deleteHandle: (data) => {
    return request("POST", `/index.php/project/deleteHandle`, data);
  },

  updateHandle: (data) => {
    return request("POST", `/index.php/project/updateHandle`, data);
  },

  getHandleInfo: (data) => {
    return request("POST", `/index.php/project/getHandleInfo`, data);
  },

  updateProjectSet: (data) => {
    return request("POST", `/index.php/project/updateProjectSet`, data);
  },

  updateVirtualRule: (data) => {
    return request("POST", `/index.php/project/updateVirtualRule`, data);
  },

  updateTestRule: (data) => {
    return request("POST", `/index.php/project/updateTestRule`, data);
  },

  getTestRule: (data) => {
    return request("POST", `/index.php/project/getTestRule`, data);
  },

  getVirtualRule: (data) => {
    return request("POST", `/index.php/project/getVirtualRule`, data);
  },

  getHandleList: (data) => {
    return request("POST", `/index.php/project/getHandleList`, data);
  },

  getStudentReportList: (data) => {
    return request("GET", `/index.php/project/getStudentReportList`, data);
  },

  unlockTotal: (data) => {
    return request("GET", `/index.php/project/unlockTotal`, data);
  },

  flushExpScore: (data) => {
    return request("GET", `/index.php/project/flushExpScore`, data);
  },

  bindVistualToExp: (data) => {
    return request("GET", `/index.php/project/bindVistualToExp`, data);
  },

  searchExpList: (data) => {
    return request("GET", `/index.php/project/searchExpList`, data);
  },

  getVistualList: (data) => {
    return request("GET", `/index.php/project/getVistualList`, data);
  },

  updateStudentScore: (data) => {
    return request("GET", `/index.php/project/updateStudentScore`, data);
  },

  urgeAppointment: (data) => {
    return request("GET", `/index.php/project/urgeAppointment`, data);
  },

  urgeReport: (data) => {
    return request("GET", `/index.php/project/urgeReport`, data);
  },

  flushTotalScore: (data) => {
    return request("GET", `/index.php/project/flushTotalScore`, data);
  },

  updateWeight: (data) => {
    return request("GET", `/index.php/project/updateWeight`, data);
  },

  searchTogether: (data) => {
    return request("GET", `/index.php/project/searchTogether`, data);
  },

  updateProjectOpenStatus: (data) => {
    return request("GET", `/index.php/project/updateProjectOpenStatus`, data);
  },

  getProjectInfo: (data) => {
    return request("GET", `/index.php/project/getProjectInfo`, data);
  },

  getStudentMarkList: (data) => {
    return request("GET", `/index.php/project/getStudentMarkList`, data);
  },

  getProjectWeight: (data) => {
    return request("GET", `/index.php/project/getProjectWeight`, data);
  },

  getProjectList: (data) => {
    return request("GET", `/index.php/project/getProjectList`, data);
  },

  deleteProject: (data) => {
    return request("GET", `/index.php/project/deleteProject`, data);
  },

  recoverOrDeleteProject: (data) => {
    return request("GET", `/index.php/project/recoverOrDeleteProject`, data);
  },

  updateProjectName: (data) => {
    return request("GET", `/index.php/project/updateProjectName`, data);
  },

  addTogether: (data) => {
    return request("GET", `/index.php/project/addTogether`, data);
  },

  importProject: (data) => {
    return request("GET", `/index.php/project/importProject`, data);
  },

  getTogetherListEsec: (data) => {
    return request("GET", `/index.php/project/getTogetherListEsec`, data);
  },

  getMarkListByGroupId: (data) => {
    return request("GET", `/index.php/project/getMarkListByGroupId`, data);
  },

  pdfDownload: (data) => {
    return request("GET", `/index.php/project/pdfDownload`, data);
  },

  updateProjectReportSet: (data) => {
    return request("POST", `/index.php/project/updateProjectReportSet`, data);
  },

  getVirtualRecordList: (data) => {
    return request("POST", `/index.php/project/getVirtualRecordList`, data);
  },

  getExpDataDeductById: (data) => {
    return request("POST", `/index.php/project/getExpDataDeductById`, data);
  },

  getExpDataRecordDataById: (data) => {
    return request("POST", `/index.php/project/getExpDataRecordDataById`, data);
  },
  // --------------------------------------------------------------------------------

  getProjectCourseInfo: (data)=>{
    return request("POST",`/index.php/project/getStatData`,data)
  },

  createReportPDF: (data)=>{
    return request("POST",`/index.php/project/createReportPDF`,data)
  },

  brushPoinrUpdate: (data)=>{
    return request("POST",`/index.php/project/brushPoinrUpdate`,data)
  },

  applyCHeckReportRepeat: (data)=>{
    return request("POST",`/index.php/project/applyCHeckReportRepeat`,data)
  },

  getReportRepeat: (data)=>{
    return request("POST",`/index.php/report/getReportRepeatS`,data)
  },

  

};